<template>
  <v-fade-transition v-if="listItem" mode="out-in">
    <v-list-item @click="toggle_dark_mode">
      <v-icon class="mr-2" :key="$vuetify.theme.dark" :color="$vuetify.theme.dark ? 'white' : 'var(--lu-primary)'">
        {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
      </v-icon>
      <v-list-item-title :style="`color: ${$vuetify.theme.dark ? 'white' : 'var(--lu-primary)'}`">
        Switch to {{ $vuetify.theme.dark ? "light" : "dark" }} theme
      </v-list-item-title>
    </v-list-item>
  </v-fade-transition>
  <v-fade-transition v-else mode="out-in">
    <v-icon :key="$vuetify.theme.dark" @click="toggle_dark_mode" color="white">
      {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
  props: {
    listItem: Boolean
  },
  methods: {
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = false;
      localStorage.setItem(
        "dark_theme",
        this.$vuetify.theme.dark.toString()
      );
    }
  }
}
</script>

<style scoped></style>

<template>
    <span>
        <div v-if="activeSummaryPage === 'Advancement'">
            <!-- <AdvancementKanban /> -->
        </div>
        <div v-else>
            <NotFound />
        </div>
    </span>
</template>
  
<script>
// import { AdvancementKanban } from "aether-advancement-kanban-vue2"
import NotFound from '../../NotFound.vue';

export default {
    components: {
        // AdvancementKanban,
        NotFound
    },
    data() {
        return {
            visibilityOptions: localStorage.getItem("userVisibility").split(','),
            activeSummaryPage: localStorage.getItem("appName")
        }
    },
    mounted() {

    }
}
</script>
  
<style scoped></style>
  
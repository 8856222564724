import moment from 'moment';
// import numeral from 'numeral';

export default {
    notBlank: str => {
        return str == null ? '---' : str;
    },
    yesNo: boolean => {        
        return boolean ? 'Yes' : 'No'
        // return boolean === 'Y' ? 'Yes' : 'No'
    },
    date: date => {
        return date == null ? '---' : moment(date).format('MMM DD, YYYY');
    },
    // dateNumber: date => {
    //     return date === null ? '-' : moment(date).format('MM-DD-YYYY');
    // },
    // currency: str => {
    //     return numeral(str).format('($0,0.00)');
    // },
    // timeSince: date => {
    //     // let momentDate = moment(date, 'YYYY-MM-DD');
      
    //     var currentDate = moment(new Date());
    //     var activityDate = moment(date);
    //     var currentDST = currentDate.isDST();
    //     var activityDST = activityDate.isDST();

    //     var timeSince = moment.duration(currentDate.diff(activityDate));
    //     var hours = timeSince.hours();
    //     if (!activityDST && currentDST)
    //     {
    //         hours = timeSince.hours() + 1;
    //     }

    //     var lastActivity = timeSince.days() + ' days ' + hours + ' hours ' + timeSince.minutes() + ' minutes ' + timeSince.seconds() + ' seconds ';

    //     return lastActivity;

    // }
}
import globals from '../globals';
import axios from 'axios';
// import { EventType, PublicClientApplication } from '@azure/msal-browser';
import * as Msal from "@azure/msal-browser"; // if using CDN, 'Msal' will be available in global scope
import { UserAgentApplication } from "msal"

export class HttpService {
    config = {
        auth: {
            authority: "https://login.microsoftonline.com/baf8218e-b302-4465-a993-4a39c97251b2/",
            clientId: globals.aetherClient,
            redirectUri: window.location.origin + '/login'
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true
        }
    }
    myMsal = new Msal.PublicClientApplication(this.config);

    delay(milliseconds) {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds)
        })
    }

    async loginAllAccounts(store) {
        await this.loginRedirect(store);


    }

    async loginRedirect(store) {
        let account = "";

        this.myMsal.handleRedirectPromise().then(async (tokenResponse) => {
            if (!tokenResponse) {
                const accounts = this.myMsal.getAllAccounts();
                if (accounts.length === 0) {
                    // No user signed in
                    console.log('redirecting')
                    this.myMsal.loginRedirect({ scopes: ["user.read"] });
                    this.myMsal.setActiveAccount();
                } else {
                    account = accounts[0]
                    console.log('login Success')
                    window.localStorage.setItem('userLibertyId', account.idTokenClaims.lu_libertyId);
                    window.localStorage.setItem('userName', tokenResponse.idTokenClaims.preferred_username);
                    window.localStorage.setItem('userPidm', account.idTokenClaims.lu_pidm);
                    window.localStorage.setItem('userDepartment', tokenResponse.idTokenClaims.lu_department);
                    window.localStorage.setItem('accountIdentifier', account.localAccountId);
                    this.setAccounts(account, store)
                }
            } else {
                // Do something with the tokenResponse
                account = tokenResponse;
                window.localStorage.setItem('userLibertyId', tokenResponse.idTokenClaims.lu_libertyId);
                window.localStorage.setItem('userName', tokenResponse.idTokenClaims.preferred_username);
                window.localStorage.setItem('userPidm', tokenResponse.idTokenClaims.lu_pidm);
                window.localStorage.setItem('userDepartment', tokenResponse.idTokenClaims.lu_department);
                window.localStorage.setItem('accountIdentifier', account.account.localAccountId);
                this.setAccounts(account.account, store)
            }
        }).catch(err => {
            // Handle error
            console.error(err);
        })
    }



    async setAccounts(account, store) {
        await this.setAetherApiToken(account);
        await this.setGatewayToken(account);
        await this.setApimToken(account);
        await this.getUserVisibility(store)
        await this.setCrmToken(account, store)
        window.location.href = localStorage.getItem('redirectUrl');
    }

    //still being used
    async silentLoginAccount(scope, account) {
        const tokenRequest = {
            scopes: [`${scope}`],
            account: account
        };

        const token = await this.myMsal.acquireTokenSilent(tokenRequest);
        return token;
    }

    async silentLoginUsername(scope, userName) {
        const tokenRequest = {
            scopes: [`${scope}`],
            loginHint: userName
        };

        const silentMsal = new UserAgentApplication(this.config);

        const token = await silentMsal.acquireTokenSilent(tokenRequest);
        return token;
    }

    async handleInteractionRequired() {
        window.localStorage.setItem('redirectUrl', window.location.href);
        window.location.href = window.location.origin + "/login";
    }

    async setCrmToken(account, store) {
        let token = "";
        const crmScope = `${globals.crmInstance}/user_impersonation`;
        const userName = localStorage.getItem("userName")

        try {
            if (account) {
                token = await this.silentLoginAccount(crmScope, account);
            } else if (userName) {
                try {
                    token = await this.silentLoginUsername(crmScope, userName)
                } catch (e) {
                    if (e.toString().startsWith('InteractionRequiredAuthError') && window.location.href !== window.location.origin + "/login") {
                        console.log('stupid error')
                        throw e
                    } else {
                        throw e
                    }
                }
            } else {
                console.log("ERROR: username or account required")
            }


            if (token) {
                window.localStorage.setItem('crm_token', token.accessToken);

                if ((!localStorage.getItem('userId')) && account && account.localAccountId) {
                    await this.setUserInfo(account.localAccountId, store);
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    async setUserInfo(accountId, store) {
        try {
            let crmData = await this.getFromCrm(
                `api/data/v9.1/systemusers?$filter=azureactivedirectoryobjectid eq '${accountId}'`
            );
            let crmResults = crmData.data.value[0];
            window.localStorage.setItem('userId', crmResults.systemuserid);
            // window.localStorage.setItem('userName', crmResults.domainname);
            // globals.userName = crmResults.domainname


            return crmResults;
        }
        catch (error) {
            console.log('Failed getting User from Account');
            console.log(error);
        }
    }

    async storePreferences() {
        let allPreferences = await this.getFromAetherInfoApi('GET_PREFERENCES')
        let userPreferences = await this.getFromAetherInfoApi('GET_USER_PREFERENCES', 'userName', globals.userName);

        for (const preference of allPreferences.data) {
            let userValue = userPreferences.data.find(p => p.PREF_ID === preference.ID)
            let prefObject = {
                id: preference.ID,
                value: userValue ? userValue.PREF_VALUE : preference.DEFAULT_VALUE
            }
            globals.userPreferences[preference.PREF_NAME] = prefObject;
        }
    }

    async updatePreference(userName, prefName, prefId, prefValue) {
        await this.getFromAetherInfoApi('UPSERT_USER_PREFERENCE', 'userName,prefId,prefValue', `${userName},${prefId},${prefValue}`)

        let updatedValue = await this.getFromAetherInfoApi('GET_SINGLE_USER_PREFERENCE', 'userName,prefId', `${userName},${prefId}`);

        globals.userPreferences[prefName].value = updatedValue.data
    }

    async setGatewayToken(account) {
        let token = "";
        var gatewayScope = `api://${globals.gatewayClientId}/Read`;
        const userName = localStorage.getItem("userName")

        try {
            if (account) {
                token = await this.silentLoginAccount(gatewayScope, account);
            } else if (userName) {
                try {
                    token = await this.silentLoginUsername(gatewayScope, userName)
                } catch (e) {
                    if (e.toString().startsWith('InteractionRequiredAuthError') && window.location.href !== window.location.origin + "/login") {
                    } else {
                        throw e
                    }
                }
            } else {
                console.log("ERROR: username or account required")
            }

            if (token) {
                window.localStorage.setItem('gateway_token', token.accessToken);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async setApimToken(account) {
        let token = "";
        var apimScope = `api://${globals.currentUsersClientId}/ReadWrite`;
        const userName = localStorage.getItem("userName")

        try {
            if (account) {
                token = await this.silentLoginAccount(apimScope, account);
            } else if (userName) {
                try {
                    token = await this.silentLoginUsername(apimScope, userName)
                } catch (e) {
                    if (e.toString().startsWith('InteractionRequiredAuthError') && window.location.href !== window.location.origin + "/login") {
                    } else {
                        throw e
                    }
                }
            } else {
                console.log("ERROR: username or account required")
            }

            if (token) {
                window.localStorage.setItem('current-users-token', token.accessToken);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async setAetherApiToken(account) {
        let token = "";
        var aetherApiScope = `api://${globals.aetherInfoClientId}/ReadWrite`;
        const userName = localStorage.getItem("userName")

        try {
            if (account) {
                token = await this.silentLoginAccount(aetherApiScope, account);
            } else if (userName) {
                try {
                    token = await this.silentLoginUsername(aetherApiScope, userName)
                } catch (e) {
                    if (e.toString().startsWith('InteractionRequiredAuthError') && window.location.href !== window.location.origin + "/login") {
                    } else {
                        throw e
                    }
                }
            } else {
                console.log("ERROR: username or account required")
            }

            if (token) {
                window.localStorage.setItem('aether-api-token', token.accessToken);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getFromAetherInfoApi(sql, params, values) {
        if (localStorage.getItem("userName")) {
            await this.setAetherApiToken()
        }

        let paramList = Array.isArray(params) ? params.join(',') : params
        let valueList = Array.isArray(values) ? values.join(',') : values
        const fullUrl = `${globals.aetherInfoApiUrl}/api/dynamicsql/${sql}?parameters=${paramList}&values=${valueList}`;

        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('aether-api-token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.get(fullUrl, config);
    }

    async getImageUrl(url) {
        if (localStorage.getItem("userName")) {
            await this.setGatewayToken()
        }

        const fullUrl = `${globals.imageUrl}/${url}`;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('gateway_token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.get(fullUrl, config);
    }

    async getFromCrm(url) {
        try {
            if (localStorage.getItem("userName")) {
                await this.setCrmToken()
            }

            let crmToken = localStorage.getItem('crm_token')

            const fullUrl = `${globals.crmInstance}/${url}`;
            const config = {
                headers: {
                    'Authorization': `Bearer ${crmToken}`,
                    'Prefer': 'odata.include-annotations=OData.Community.Display.V1.FormattedValue',
                    'Content-Type': 'application/json'
                }
            };

            return await axios.get(fullUrl, config);
        } catch (err) {
            console.log(err)
        }
    }

    async postToCrm(url, data) {
        if (localStorage.getItem("userName")) {
            await this.setCrmToken()
        }

        const fullUrl = `${globals.crmInstance}/${url}`;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('crm_token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.post(fullUrl, data, config);
    }

    async patchCrm(url, data) {
        if (localStorage.getItem("userName")) {
            await this.setCrmToken()
        }

        const fullUrl = `${globals.crmInstance}/${url}`;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('crm_token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.patch(fullUrl, data, config);
    }

    async postToApim(data) {
        if (localStorage.getItem("userName")) {
            await this.setApimToken()
        }

        const fullUrl = globals.currentUsersAPIUrl;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('current-users-token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.post(`${fullUrl}/currentusers`, data, config);
    }

    async getFromApim(url) {
        if (localStorage.getItem("userName")) {
            await this.setApimToken()
        }

        const fullUrl = globals.currentUsersAPIUrl;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('current-users-token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.get(fullUrl + url, config);
    }

    async getUserVisibility(store) {
        let distinctRoles = [];

        if (store.state.userRoles.length > 0) {
            distinctRoles = store.state.userRoles
        } else {
            let aetherTeamRoles = await this.getFromAetherInfoApi('GET_ASSOCIATED_TEAMS_AND_ROLES', 'emailAddress', localStorage.getItem('userName'))

            for (const role of aetherTeamRoles.data) {
                if (!distinctRoles.find(r => r.role === role.ROLE_NAME)) {
                    try {
                        distinctRoles.push(role.ROLE_NAME);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
            store.commit('setUserRoles', distinctRoles)
        }

        let activeVisibility = [];

        for (const role of distinctRoles) {
            if (role) {
                let roleName = role.slice(0, -3)
                //space required before UI - eliminates roles such as LUO DUI
                if (role.slice(-3) === " UI" && !activeVisibility.find(v => v === roleName)) {
                    if (role.startsWith('Enrollment')) {
                        if (!activeVisibility.find(v => v === "LUO")) {
                            activeVisibility.push("LUO")
                        }
                    } else {
                        activeVisibility.push(roleName)
                    }
                }
            }
        }

        let currentApp = localStorage.getItem('appName')
        if (!currentApp) {
            localStorage.setItem('appName', activeVisibility[0])
        }
        window.localStorage.setItem('userVisibility', activeVisibility)
    }
}
export default new HttpService();
import injector from 'vue-inject';
import globals from '../globals';
import httpService from './http.service';

export class ViewingUsersService {

    async fetchViewingUsers(type, id) {
        try {
            const users = [];
            if (type && id) {
                const body = {
                    "system": "Aether",
                    "entity": type,
                    "recordId": id,
                    // "userId": localStorage.getItem('userId'),
                    "userId": localStorage.getItem('userName'),
                    "secondInterval": 600
                }

                const apiResponse = await httpService.postToApim(JSON.stringify(body));

                for (let i = 0; i < apiResponse.data.length; i++) {
                    users.push({
                        alt: apiResponse.data[i].firstName + ' ' + apiResponse.data[i].lastName,
                        id: apiResponse.data[i].userId,
                        presence: apiResponse.data[i].availability,
                        presenceDesc: await this.camelPad(apiResponse.data[i].activity),
                        role: apiResponse.data[i].jobTitle,
                        src: await this.photoFunc(apiResponse.data[i])
                    });
                }
            }
            return users;
        }
        catch (error) {
            console.log(error)
        }
    }

    async fetchCurrentUser() {
        try {
            let apiResponse = await httpService.getFromApim('/user');
            let user = [];
            if (apiResponse.data != null) {
                user = {
                    name: apiResponse.data.firstName + ' ' + apiResponse.data.lastName,
                    id: apiResponse.data.userId,
                    presence: apiResponse.data.availability,
                    presenceDesc: await this.camelPad(apiResponse.data.activity),
                    jobTitle: apiResponse.data.jobTitle,
                    src: await this.photoFunc(apiResponse.data)
                };
            }
            return user;
        }
        catch (error) {
            console.log(error)
        }
    }

    async photoFunc(data) {
        if (data.photo != undefined) {
            return `data:${data.photo.imageType};base64, ${data.photo.base64Photo}`
        }
        return null
    }

    async camelPad(str) {
        return str
            // Look for long acronyms and filter out the last letter
            .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
            // Look for lower-case letters followed by upper-case letters
            .replace(/([a-z\d])([A-Z])/g, '$1 $2')
            // Look for lower-case letters followed by numbers
            .replace(/([a-zA-Z])(\d)/g, '$1 $2')
            .replace(/^./, function (str) { return str.toUpperCase(); })
            // Remove any white space left around the word
            .trim();
    }
}

export default new ViewingUsersService();
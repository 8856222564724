var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.windowSize.x > 750)?_c('div',{key:_vm.loading,staticClass:"rcorners1 app-nav-icons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"white"},on:{"click":_vm.goBack}},'v-btn',props,false),on),[_c('iconify-icon',{staticStyle:{"color":"white","font-size":"30px"},attrs:{"icon":"solar:round-alt-arrow-left-outline"}})],1)]}}],null,false,1817511100)},[_vm._v(" Back ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"white"},on:{"click":_vm.refreshPage}},'v-btn',props,false),on),[_c('iconify-icon',{staticStyle:{"color":"white","font-size":"30px"},attrs:{"icon":"solar:refresh-circle-outline"}})],1)]}}],null,false,3648652903)},[_vm._v(" Refresh ")]),_c('PinnedRecords',{key:_vm.relatedRecord + 'pinned',attrs:{"listItem":false,"relatedRecord":_vm.relatedRecord}})],1):_c('v-menu',{attrs:{"offset-y":"","left":"","nudge-bottom":"14","min-width":"230","content-class":"navigation-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrs = ref.attrs;
return [_c('iconify-icon',_vm._g(_vm._b({staticStyle:{"color":"white","font-size":"30px"},attrs:{"icon":"solar:menu-dots-circle-outline"}},'iconify-icon',attrs,false),onMenu))]}}])},[_c('v-list',{staticStyle:{"background-color":"var(--lu-primary)","opacity":"0.9","width":"fit-content"}},[(_vm.showSearch)?_c('v-list-item',{staticClass:"mr-2  goBack",staticStyle:{"margin-top":"-1rem"},on:{"click":_vm.goBack}},[_c('iconify-icon',{staticStyle:{"color":"white","font-size":"20px"},attrs:{"icon":"solar:round-alt-arrow-left-outline"}}),_c('div',{staticClass:"list-label"},[_vm._v("Back")])],1):_vm._e(),(_vm.showSearch)?_c('v-divider',{staticStyle:{"opacity":"0.3"},attrs:{"color":"white"}}):_vm._e(),_c('v-list-item',{staticClass:"mr-2 refresh",on:{"click":_vm.refreshPage}},[_c('iconify-icon',{staticStyle:{"color":"white","font-size":"20px"},attrs:{"icon":"solar:refresh-circle-outline"}}),_vm._v("   "),_c('div',{staticClass:"list-label"},[_vm._v("Refresh")])],1),_c('v-divider',{staticStyle:{"opacity":"0.3"},attrs:{"color":"white"}}),_c('PinnedRecords',{key:_vm.relatedRecord + 'pinned',attrs:{"listItem":true,"relatedRecord":_vm.relatedRecord}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
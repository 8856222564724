<template>
    <v-app>
        <v-main>
            <v-overlay :absolute="true" :z-index="56" :opacity=".75" :value="true">
                An error has occurred. Please reload. If the problem persists, please contact crmdev@liberty.edu.
            </v-overlay>
        </v-main>
    </v-app>
</template>

<script>
export default {
    dependencies: ["httpService", "userService"],
    data() {
        return {
            loginSuccess: false
        };
    },
    methods: {
        onRefresh() {
            console.log('attempting to login')
            localStorage.setItem("shouldRedirect", "true")
        }
    },
    async mounted() {
        window.addEventListener('beforeunload', this.onRefresh)

        if(localStorage.getItem('shouldRedirect') === "true") {
            localStorage.setItem("shouldRedirect", "false")
            window.location.href = window.location.origin + "/login";
        } else {
            localStorage.setItem("shouldRedirect", "false")
        }
    }
}
</script>
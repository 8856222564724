import * as httpService from 'aether-service-library'
import moment from 'moment';

class NotificationService {
    async fetchNotifications() {
        try {
            let apiRes = await httpService.getFromAetherInfoApi('GET_NOTIFICATIONS_FOR_USER', 'read,pidm', `N,${localStorage.getItem('userPidm')}`);


            return apiRes.data.map(r => {
                return {
                    id: r.ID,
                    text: r.TEXT,
                    regardingId: r.REGARDING_ID,
                    regardingType: r.REGARDING_TYPE,
                    activityId: r.ACTIVITY_ID,
                    activityType: r.ACTIVITY_TYPE,
                    taggedId: r.TAGGED_ID,
                    taggedType: r.TAGGED_TYPE,
                    createdBy: r.CREATED_BY,
                    activityDate: moment(r.ACTIVITY_DATE).format('MM/DD/YY'),
                    duration: this.getDuration(r.ACTIVITY_DATE),
                    regardingName: r.REGARDING_NAME,
                    taggedName: r.TAGGED_NAME,
                    createdByName: r.CREATED_BY_NAME
                }
            })
        } catch (error) {
            // alert(error)
            console.log(error)
        }
    }

    // *This is set up to format in the same way Outlook puts a date/timestamp on emails
    getDuration(passedDate) {
        let today = moment();
        let date = moment(passedDate)

        if (today.diff(date, 'days') < 1 && today.format('D') <= date.format('D')) {
            return date.format('h:mm A')
        } else if (today.diff(date, 'weeks') < 1) {
            return date.format('ddd h:mm A')
        } else if (today.diff(date, 'months') < 1) {
            return date.format('ddd M/D')
        } else {
            return date.format('M/D/YYYY')
        }
    }

    async readNotificationById(id) {
        try {
            await httpService.getFromAetherInfoApi('MARK_NOTIFICATION_AS_READ', 'id', id);
        } catch (error) {
            console.log(error)
        }
    }

    async readAllNotifications(taggedId) {
        try {
            await httpService.getFromAetherInfoApi('READ_ALL_NOTIFICATIONS_FOR_CONTACT', 'pidm', taggedId);
        } catch (error) {
            console.log(error)
        }
    }
}

export default new NotificationService();

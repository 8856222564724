<script>
export default {
    props: {
        notification: Object
    },
    data() {
        return {
            hovering: false
        }
    },
    mounted() {
    },
    methods: {
        getTitle(item) {
            if (item.taggedType === 'Contact') {
                if (item.activityType) {
                    return `${item.createdByName} tagged you in ${this.getAOrAn(item.activityType)} ${item.activityType}`
                } else {
                    return `${item.createdByName} tagged you in ${this.getAOrAn(item.regardingType)} ${item.regardingType}:`
                }
            }
        },
        getAOrAn(followingWord) {
            let firstLetter = followingWord.substring(0, 1).toLowerCase();
            let vowels = ['a', 'e', 'i', 'o', 'u'];
            if (vowels.find(v => v === firstLetter)) {
                return 'an'
            } else {
                return 'a'
            }
        }
    }
}
</script>

<template>
    <div class="notification" @mouseover="hovering = true" @mouseleave="hovering = false"
        @click="$emit('click-notification', notification)">
        <v-row class="ma-0">
            <v-col cols="7">
                <span class="notification-title font-weight-bold">{{ notification.createdByName }} </span>
                <span class="notification-title font-weight-medium">
                    tagged you in {{ this.getAOrAn(notification.regardingType) }}
                </span>
                <span class="notification-title font-weight-bold">{{ notification.regardingType }}</span>
                <span class="notification-title font-weight-medium">:</span>
            </v-col>
            <v-col cols="5" class="text-right notification-date">
                <div>{{ notification.duration }}</div>
            </v-col>
        </v-row>
        <v-row class="ma-0">
            <div class="mt-n2 mb-3 ml-4 truncate-text font-italic font-weight-bold">{{ notification.text }}</div>
            <v-spacer></v-spacer>
            <v-btn v-show="hovering" color="var(--lu-alert)" class="close-btn mt-n4 mr-3" icon outlined small
                @click.stop="$emit('clear-notification', notification)">
                <iconify-icon icon="ic:round-close" style="font-size: 18px;" />
            </v-btn>
        </v-row>
    </div>
</template>

<style>
.notification {
    width: 350px;
}

.notification:hover {
    /* background-color: var(--basic-background-color); */
    cursor: pointer;
}

.notification-title {
    line-height: 18px;
    color: var(--basic-text-color);
    font-weight: 500 !important;
}

.truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--basic-subtext-color);
    max-width: 270px !important;
}

.close-btn {}
</style>
<script>
import ProfileAvatar from "./profile/ProfileAvatar.vue"
import ProfilePresence from "./profile/ProfilePresence.vue"
import ProfileTooltip from "./profile/ProfileTooltip.vue"
import { mdiDotsHorizontal, mdiAccount } from '@mdi/js';
import globals from "../../../globals";
import viewingUsersService from "../../../services/viewingUsers.service";

export default ({
    props: {
        isMenu: Boolean
    },
    components: {
        ProfileAvatar,
        ProfilePresence,
        ProfileTooltip
    },
    setup() {
        return {
            icons: {
                mdiDotsHorizontal,
                mdiAccount
            }
        }
    },
    data() {
        return {
            avatars: [],
            menuMaxHeight: `${(60 * 5) + 4}px`,
            presence: true,
            stackedLimit: 3,
            stackedMenu: false,
            pollInterval: null
        }
    },
    async mounted() {
        try {
            var sleep = duration => new Promise(resolve => setTimeout(resolve, duration))
            var poll = (promiseFn, duration) => promiseFn().then(
                sleep(duration).then(() => poll(promiseFn, duration)))

            poll(() => new Promise(() => this.fetchData()), 30000);
        } catch (error) {
            this.error = true;
        } finally {
            this.loading = false;
        }
    },
    computed: {
        avatarsSorted() {
            return (this.avatars && this.avatars.length > 0)
                ? this.avatars.sort((a, b) => a.alt.localeCompare(b.alt))
                : []
        },
        avatarsStackedLimited() {
            return (this.avatarsSorted && this.avatarsSorted.length > 0)
                ? this.avatarsSorted.slice(0, this.stackedLimit)
                : []
        },

    },

    methods: {
        async fetchData() {
            let pidm = sessionStorage.getItem('pidm')
            let oppId = sessionStorage.getItem('oppId')

            if (pidm && pidm !== 'null' && pidm !== 'undefined') {
                this.avatars = await viewingUsersService.fetchViewingUsers('Contact', pidm);
            } else if (oppId && oppId !== 'null' && oppId !== 'undefined') {
                this.avatars = await viewingUsersService.fetchViewingUsers('Opportunity', oppId);
            }
        },
        presenceColor(presence) {
            switch (presence) {
                case 'Away':
                case 'BeRightBack':
                    return 'yellow'
                case 'Busy':
                case 'BusyIdle':
                case 'DoNotDisturb':
                    return 'red'
                case 'Offline':
                case 'PresenceUnknown':
                    return 'grey'
                case 'Available':
                case 'AvailableIdle':
                    return 'success'
                default:
                    return 'grey'
            }
        }
    }
})
</script>

<template>
    <div>
        <section v-if="isMenu">
            <v-menu offset-y left nudge-bottom="14" nudge-right="66" min-width="230"
                content-class="navigation-menu-content" :disabled="avatars.length === 0">
                <template v-slot:activator="{ on: onMenu, attrs }">
                    <!-- <v-badge x-small bordered top overlap
                        :content="5" :value="5" v-bind="attrs" v-on="onMenu">
                    
                    </v-badge> -->
                    <div class="rounded-circle current-user-menu" v-bind="attrs" v-on="onMenu">
                        <v-icon small color="white" class="mt-1">
                            {{ icons.mdiAccount }}
                        </v-icon>
                        {{ avatars.length }}
                    </div>
                </template>
                <v-list style="background-color: var(--lu-primary);">
                    <div v-for="(avatar, index) in avatars" style="background-color: var(--lu-primary);"
                        :key="`avatar-id-${avatar.id}`" class="avatars-group__item">
                        <span small icon v-bind="props" v-on="on">
                            <v-badge bordered bottom overlap dot :color="presenceColor(avatar.presence)" offset-x="12"
                                offset-y="12" class="ms-4">
                                <ProfileAvatar :avatar="avatar"></ProfileAvatar>
                            </v-badge>
                        </span>
                        <span class="ml-2" style="color: white;">
                            {{ avatar.alt }}
                        </span>
                        <v-divider color="white" style="opacity: 0.3;" class="mt-1 mb-1"
                            v-if="index < avatars.length - 1"></v-divider>
                    </div>
                </v-list>
            </v-menu>
        </section>
        <section v-else class="avatars-group pa-3 stacked">
            <div v-for="avatar in avatarsStackedLimited" :key="`avatar-id-${avatar.id}`" class="avatars-group__item">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, props }">
                        <span small icon v-bind="props" v-on="on">
                            <v-badge bordered bottom overlap dot :color="presenceColor(avatar.presence)" offset-x="12"
                                offset-y="12" class="ms-4">
                                <ProfileAvatar :avatar="avatar"></ProfileAvatar>
                            </v-badge>
                        </span>
                    </template>
                    <profile-tooltip :avatar="avatar"></profile-tooltip>
                </v-tooltip>
            </div>
            <div class="avatars-group__item more" v-if="avatarsSorted.length > stackedLimit">
                <v-menu v-model="stackedMenu" left :max-height="menuMaxHeight" nudge-bottom="8" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn style="background-color: var(--lu-primary)" v-bind="attrs" v-on="on" icon>
                            <v-icon color="white">{{ icons.mdiDotsHorizontal }}</v-icon>
                        </v-btn>
                    </template>
                    <v-list two-line>
                        <v-list-item v-for="avatar in avatarsSorted" :key="`avatar-menu-id-${avatar.id}`" avatar>
                            <!-- <v-list-item-avatar> -->
                            <v-badge bordered bottom overlap dot :color="presenceColor(avatar.presence)" offset-x="12"
                                offset-y="12" class="ms-4">
                                <ProfileAvatar :avatar="avatar" custom-class="bordered small" size="32px">
                                </ProfileAvatar>
                            </v-badge> &nbsp;&nbsp;&nbsp;
                            <!-- </v-list-item-avatar> -->
                            <v-list-item-content>
                                <v-list-item-title>{{ avatar.alt }}</v-list-item-title>
                                <v-list-item-subtitle>{{ avatar.role }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </section>
    </div>
</template>

<style lang="scss" scoped>
.current-user-menu {
    border: 2px solid white;
    color: white;
    padding: 4px;
    display: inline-flex;
    margin-top: 2px;
    margin-right: -5px;
}

.avatars-group {
    &.grid {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
    }

    &.stacked {
        display: flex;
        flex-direction: row;
        direction: ltr;
        max-width: 100%;
        overflow: hidden;
        overflow-x: hidden;
        white-space: nowrap;

        &>* {
            margin-right: -20px;
        }

        &>*:last-of-type {
            padding-right: 16px;
        }
    }

    .v-avatar {
        // box-shadow: 0px 0px 0px 2px #fff inset;

        img {
            padding: 2px;
        }

        span {
            align-items: center;
            display: flex;
            font-size: 110%;
            font-weight: 700;
            height: 100%;
            justify-content: center;
            letter-spacing: 0.1rem;
            text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.56);
            width: inherit;
        }
    }
}

.avatars-group__item {
    cursor: default;
    transition: all 0.1s ease-out;

    &.more {
        align-items: center;
        display: flex;
        margin-left: 15px !important;

        &:hover {
            transform: none;
        }
    }

    &:hover {
        transform: translateY(-4px);
        z-index: 1;
    }
}
</style>
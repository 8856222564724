<template>
  <v-app>
    <v-main>
      <v-overlay :absolute="true" :z-index="56" :opacity=".75" :value="true">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <!-- <div>Login Success: {{ loginSuccess }}</div> -->
    </v-main>
  </v-app>
</template>

<script>
import httpService from '../services/http.service';

export default {
  data() {
    return {
      loginSuccess: false
    };
  },
  methods: {
  },
  async mounted() {
    await httpService.loginAllAccounts(this.$store);
  }
}
</script>

<style scoped></style>

<script>
export default ({
    props: {
        avatar: {
            default: () => { },
            type: Object
        }
    },    
})
</script>

<template>
    <v-layout column>
        <span class="font-weight-medium text-uppercase">
            <small>{{ avatar.presenceDesc }}</small>
        </span>
        <span class="font-weight-medium">{{ avatar.alt }}</span>
        <span>{{ avatar.role }}</span>
    </v-layout>
</template>
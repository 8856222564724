<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <SplitViewNavigationDrawer
      v-if="splitView && opportunityPage"
      :allOpportunities="allOpportunities"
      :pipeline="pipeline"
    />
    <div v-else>
      <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img
            v-if="this.$vuetify.theme.dark"
            :src="require('@/assets/images/logos/Aether_Logo_white.svg')"
            max-width="280"
            height="50"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
          <v-img
            v-else
            :src="require('@/assets/images/logos/Aether_Logo.svg')"
            max-width="280"
            height="50"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
          <v-slide-x-transition> </v-slide-x-transition>
        </router-link>
      </div>

      <!-- Navigation Items -->
      <v-list expand shaped class="vertical-nav-menu-items pr-5">
        <nav-menu-link
          title="Search"
          :to="`/${appName.replace(' ', '')}/search`"
          icon="solar:magnifer-outline"
        ></nav-menu-link>
        <ReportContainer v-if="uDevApps.find(a => a === appName)"></ReportContainer>
        <nav-menu-link
          v-if="opportunityApps.find(a => a === appName)"
          title="Dashboards"
          :to="`/${appName.replace(/\s+/g, '')}/dashboards`"
          icon="akar-icons:dashboard"
        ></nav-menu-link>
        <nav-menu-link
          v-if="opportunityApps.find(a => a === appName)"
          title="Opportunities"
          :to="`/${appName.replace(/\s+/g, '')}/opportunities`"
          icon="solar:soundwave-square-outline"
        ></nav-menu-link>
      </v-list>
      <v-list v-if="!showSearch && !showAdmin && contactPage" expand shaped class="vertical-nav-menu-items pr-5">
        <nav-menu-section-title title="CONTACT INFORMATION"></nav-menu-section-title>
        <nav-menu-link
          v-if="contactPage"
          title="Summary"
          :to="`/${appName.replace(/\s+/g, '')}/${luid}/summary`"
          icon="solar:home-outline"
        ></nav-menu-link>
        <nav-menu-link
          v-if="opportunityApps.find(a => a === appName)"
          title="Contact Opportunities"
          :to="`/${appName.replace(/\s+/g, '')}/${luid}/opportunities`"
          icon="solar:soundwave-circle-outline"
        ></nav-menu-link>
        <nav-menu-link
          v-if="contactPage && !(appName === 'General') && !uDevApps.find(a => a === appName)"
          title="Details"
          :to="`/${appName.replace(/\s+/g, '')}/${luid}/details`"
          icon="solar:list-broken"
        ></nav-menu-link>
        <nav-menu-link
          v-if="contactPage && (appName === 'LUO' || appName === 'LUOA')"
          title="Workbench"
          :to="`/${appName.replace(/\s+/g, '')}/${luid}/workbench`"
          icon="solar:case-round-outline"
        ></nav-menu-link>
        <TabContainers v-if="contactPage" :editing="editTabOrder" />
        <nav-menu-section-title title="COMMUNICATIONS" v-if="!(appName === 'General')"></nav-menu-section-title>
        <nav-menu-link
          v-if="uDevApps.find(a => a === appName)"
          title="Eloqua"
          :to="`/${appName.replace(/\s+/g, '')}/${luid}/Eloqua`"
          icon="mdi:dots-hexagon"
        ></nav-menu-link>
        <nav-menu-link
          v-if="!(appName === 'General')"
          title="Notes & Activities"
          :to="`/${appName.replace(/\s+/g, '')}/${luid}/notes-and-activities`"
          icon="solar:notes-outline"
        ></nav-menu-link>
        <nav-menu-link
          v-if="!(appName === 'General')"
          title="Comm Preferences"
          :to="`/${appName.replace(/\s+/g, '')}/${luid}/bcp`"
          icon="solar:call-chat-linear"
        ></nav-menu-link>
        <nav-menu-link
          v-if="!(appName === 'General')"
          title="Mass Comms"
          :to="`/${appName.replace(/\s+/g, '')}/${luid}/massComms`"
          icon="solar:inbox-archive-outline"
        ></nav-menu-link>
      </v-list>
      <AetherSecurity v-if="showAdmin" />
      <v-list v-if="appName === 'LUO'" expand shaped class="vertical-nav-menu-items pr-5">
        <nav-menu-section-title title="WORKBENCH FORMS"></nav-menu-section-title>
        <nav-menu-link
          title="LUO Declarations"
          :to="`/${appName.replace(/\s+/g, '')}/declarations`"
          icon="solar:notebook-outline"
        ></nav-menu-link>
        <nav-menu-link
          title="Manual Registrations"
          :to="`/${appName.replace(/\s+/g, '')}/manual_registrations`"
          icon="solar:notebook-outline"
        ></nav-menu-link>
        <nav-menu-link
          title="Campus Switches"
          :to="`/${appName.replace(/\s+/g, '')}/campus_switches`"
          icon="solar:notebook-outline"
        ></nav-menu-link>
        <nav-menu-link
          title="LUO Feedback"
          :to="`/${appName.replace(/\s+/g, '')}/feedback`"
          icon="solar:notebook-outline"
        ></nav-menu-link>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import globals from '@/globals'
import { TabContainers } from 'aether-tabcontainers-vue2'
import { ReportContainer } from 'aether-reports-vue2'
import customConfigService from '../../../services/customConfig.service'
import { AetherSecurity } from 'aether-admin-vue2'
import { SplitViewNavigationDrawer } from 'aether-opportunities-vue2'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
    ReportContainer,
    TabContainers,
    AetherSecurity,
    SplitViewNavigationDrawer,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
    editTabOrder: {
      type: Boolean,
      default: false,
    },
    allOpportunities: Array,
    pipeline: Number,
    splitView: Boolean,
  },
  data() {
    return {
      roles: [],
      moneyTabs: [],
      bannerTabs: [],
      bannerEditTabs: [],
      appName: localStorage.getItem('appName'),
      showSearch: false,
      showAdmin: false,
      showWorkbenchForms: false,
      tabsLoaded: false,
      luid: sessionStorage.getItem('libertyId'),
      contactPage: false,
      uDevApps: globals.uDevApps,
      opportunityApps: globals.uDevApps,
      opportunityPage: false,
    }
  },
  async mounted() {
    try {
      await customConfigService.setBannerDown()

      const luidRegex = /^[L,l][0-9]{8,10}$/
      const urlArr = window.location.pathname.split('/')
      for (var x = 0; x < urlArr.length; x++) {
        if (luidRegex.test(urlArr[x])) {
          this.contactPage = true
          break
        } else if (urlArr[x] === 'opportunities') {
          this.opportunityPage = true
        }
      }

      if (window.location.pathname.includes('admin')) {
        this.showAdmin = true
      } else {
        this.showAdmin = false
      }
    } catch (error) {
      this.error = true
    } finally {
      this.loading = false
    }
  },
  methods: {},
  watch: {
    $route(to, from) {
      this.luid = sessionStorage.getItem('libertyId')
      if (to.path.includes('admin')) {
        this.showAdmin = true
      } else {
        this.showAdmin = false
      }

      const luidRegex = /^[L,l][0-9]{8,10}$/
      const urlArr = window.location.pathname.split('/')
      for (var i = 0; i < urlArr.length; i++) {
        if (luidRegex.test(urlArr[i])) {
          this.contactPage = true
          break
        } else {
          this.contactPage = false
        }
      }
      if (window.location.pathname.includes('opportunities')) {
        this.opportunityPage = true
      } else {
        this.opportunityPage = false
      }
    },
  },
  computed: {},
  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  margin-left: -20px;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.vertical-nav-menu-items.theme--light {
  color: white !important;
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
import globals from '../globals';
import moment from 'moment';
import httpService from './http.service';
import axios from 'axios';

export class ActivityService {

    async fetchAssociatedActivities(store) {
        try {
            let fetchXmlQuery = `<fetch top="50" version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
            <entity name="activitypointer">
              <attribute name="activitytypecode" />
              <attribute name="subject" />
              <attribute name="statecode" />
              <attribute name="statuscode" />
              <attribute name="prioritycode" />
              <attribute name="modifiedon" />
              <attribute name="createdon" />
              <attribute name="activityid" />
              <attribute name="ownerid" />
              <attribute name="description" />
              <attribute name="createdby" />
              <order attribute="createdon" descending="true" />
              <filter type="or">
                <condition attribute="regardingobjectid" operator="eq" uitype="contact" value='${sessionStorage.getItem('contactId')}' />
                <condition entityname="party" attribute="partyid" operator="eq" value='${sessionStorage.getItem('contactId')}' uitype="contact" />
              </filter>
              <filter type="and">
                <condition attribute="activitytypecode" operator="in">
                  <value>4201</value>
                  <value>10117</value>
                  <value>4202</value>
                  <value>4207</value>
                  <value>4210</value>
                  <value>10147</value>
                  <value>4212</value>
                </condition>
              </filter>
              <link-entity name="systemuser" from="systemuserid" to="ownerid" alias="Owner">
                <attribute name="photourl" />
                <attribute name="entityimageid" />
              </link-entity>
              <link-entity name="activityparty" from="activityid" to="activityid" link-type="inner" alias="party" />
            </entity>
          </fetch>`;

          let crmData = await httpService.getFromCrm(
                `api/data/v9.1/activitypointers?fetchXml=${encodeURIComponent(fetchXmlQuery)}`
            );
            let crmResults = crmData.data.value;
            let filterType = [];
            let filterStatus = [];
            let filterState = [];
            filterType.push('All');
            filterState.push('All');
            filterStatus.push('All');
            for (var i = 0; i < crmResults.length; i++) {
                const activtyType = await this.getBetterTypeName(crmResults[i]['activitytypecode']);
                const statecode = crmResults[i]['statecode@OData.Community.Display.V1.FormattedValue'];
                const statuscode = crmResults[i]['statuscode@OData.Community.Display.V1.FormattedValue'];

                if (!filterType.includes(activtyType)) {
                    filterType.push(activtyType);
                }
                if (!filterState.includes(statecode)) {
                    filterState.push(statecode);
                }
                if (!filterStatus.includes(statuscode)) {
                    filterStatus.push(statuscode);
                }
                store.commit('setActivityTypes', filterType);
                store.commit('setStatecodes', filterState);
                store.commit('setStatuscodes', filterStatus);

                const description = crmResults[i]['description'];


                store.commit('addNotesAndActivities', {
                    unqIndex: 'A' + i,
                    id: crmResults[i]['activityid'],
                    type: activtyType,
                    subject: crmResults[i]['subject'] != null ? crmResults[i]['subject'] : '<i>No subject</i>',
                    text: crmResults[i]['description'],
                    textNoTag: description != null ? description.replace(/(<([^>]+)>)/ig, '') : '',
                    createdon: crmResults[i]['createdon'],
                    createDateFormatted: moment(crmResults[i].createdon).format('lll'),
                    createdBy: await this.formatName(crmResults[i]['_createdby_value@OData.Community.Display.V1.FormattedValue']),
                    photo: crmResults[i]['Owner.entityimageid_entityimage_url'],
                    alt: await this.formatName(crmResults[i]['_createdby_value@OData.Community.Display.V1.FormattedValue']),
                    src: await this.getPhotoUrl(crmResults[i]['Owner.entityimageid_entityimage_url']),
                    name: await this.formatName(crmResults[i]['_ownerid_value@OData.Community.Display.V1.FormattedValue']),
                    statecode: statecode,
                    statuscode: statuscode,
                    link: `${globals.crmInstance}/main.aspx?forceUCI=1&newWindow=true&pagetype=entityrecord&etn=${crmResults[i]['activitytypecode']}&id=${crmResults[i]['activityid']}`
                })
            }
            return;
            // return activities;
        }
        catch (error) {
            // alert(error)
            console.log(error)
        }
    }

    async fetchAssociatedNotes(store) {
        try {
            // No Attachments in this call
            const fetchXmlQuery = `<fetch top="50" version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                <entity name="annotation">
                <attribute name="subject" />
                <attribute name="notetext" />              
                <attribute name="annotationid" />
                <attribute name="ownerid" />
                <attribute name="modifiedon" />
                <attribute name="modifiedby" />
                <attribute name="createdon" />
                <attribute name="createdby" />
                <order attribute="createdon" descending="true" />
                <filter type="and">
                    <condition attribute="objectid" operator="eq" uitype="contact" value="${sessionStorage.getItem('contactId')}" />
                    <condition attribute="isdocument" operator="eq" value="0" />
                </filter>
                <link-entity name="systemuser" from="systemuserid" to="createdby" link-type="inner" alias="ab">
                    <attribute name="photourl" />
                    <attribute name="entityimageid" />
                </link-entity>
                </entity>
            </fetch>`
            let crmData = await httpService.getFromCrm(`api/data/v9.2/annotations?fetchXml=${encodeURIComponent(fetchXmlQuery)}`
            );
            let crmResults = crmData.data.value;

            if (crmResults.length > 0) {
                store.commit('addActivityType', 'Note');
            }
            for (var i = 0; i < crmResults.length; i++) {
                store.commit('addNotesAndActivities', {
                    unqIndex: 'N' + i,
                    id: crmResults[i]['annotationid'],
                    type: 'Note',
                    subject: crmResults[i]['subject'] != null ? crmResults[i]['subject'] : '<i>No subject</i>',
                    text: crmResults[i]['notetext'],
                    textNoTag: crmResults[i]['notetext'],
                    createdon: crmResults[i]['createdon'],
                    createDateFormatted: moment(crmResults[i].createdon).format('lll'),
                    createdBy: await this.formatName(crmResults[i]['_createdby_value@OData.Community.Display.V1.FormattedValue']),
                    statecode: '',
                    statuscode: '',
                    photo: await this.getPhotoUrl(crmResults[i]['ab.entityimageid_entityimage_url']),
                    alt: await this.formatName(crmResults[i]['_createdby_value@OData.Community.Display.V1.FormattedValue']),
                    src: await this.getPhotoUrl(crmResults[i]['ab.entityimageid_entityimage_url']),
                    name: await this.formatName(crmResults[i]['_createdby_value@OData.Community.Display.V1.FormattedValue'])
                })
            }
        }
        catch (error) {
            // alert(error)
            console.log(error)
        }
    }

    async fetchTouchpoints(store) {
        let apiResponse = await httpService.getFromAetherInfoApi('GET_TOUCHPOINTS', 'luid', sessionStorage.getItem('libertyId'))
        let data = apiResponse.data;

        if (data.length > 0) {
            store.commit('addActivityType', 'Touchpoint');
        }

        for (var i = 0; i < data.length; i++) {
            store.commit('addNotesAndActivities', {
                unqIndex: 'T' + i,
                accountId: data[i].PACIOLAN_ACCOUNT_ID,
                contact: data[i].TOUCHPOINT_CONTACT,
                contactMethod: data[i].CONTACT_METHOD,
                text: data[i].DESCRIPTION,
                textNoTag: data[i].DESCRIPTION,
                createDateFormatted: moment(data[i].ORIGINATION_DATE).format('MMM DD, YYYY'),
                createdon: data[i].ORIGINATION_DATE,
                createdBy: data[i].TOUCHPOINT_OWNER,
                statecode: data[i].STATUS,
                subject: data[i].SUBJECT,
                updatedBy: data[i].UPDATED_BY,
                updatedDate: moment(data[i].UPDATED_ON).format('MMM DD, YYYY'),
                src: null,
                alt: data[i].TOUCHPOINT_OWNER,
                type: 'Touchpoint'
            });
        }
    }

    async createNote(subject, text) {
        try {
            const body = {
                "notetext": text,
                "subject": subject,
                "objectid_contact@odata.bind": `/contacts(${sessionStorage.getItem('contactId')})`
            };

            const apiResponse = await httpService.postToCrm(`api/data/v9.2/annotations`, JSON.stringify(body));
            return apiResponse;
        } catch (error) {
            console.log(error);
        }
    }

    async groupActivities(activities) {
        let groupedActivities = [];
        for (const activity of activities) {
            const createMonthFormatted = moment(activity.createdon).format('MMMM yyyy');
            const groupIndex = groupedActivities.findIndex(x => x.createMonthFormatted === createMonthFormatted);

            if (groupIndex > -1) {
                groupedActivities[groupIndex].activities.push(activity)
            } else {
                groupedActivities.push({
                    createMonth: moment(activity.createdon).month(),
                    createYear: moment(activity.createdon).year(),
                    createMonthFormatted, activities: [activity]
                })
            }
        } return groupedActivities.sort(function (a, b) {
            if (a.createYear < b.createYear) { return 1; }
            else if (a.createYear > b.createYear) { return -1 }
            else {
                if (a.createMonth < b.createMonth) { return 1; }
                else if (a.createMonth > b.createMonth) { return -1 } else { return 0 }
            }
        })
    }

    async getBetterTypeName(typeCode) {
        switch (typeCode) {
            case 'lu_chat':
                return 'Chat';
            case 'lu_sms':
                return 'SMS';
            case 'task':
                return 'Task';
            case 'letter':
                return 'Letter';
            case 'email':
                return 'Email';
            case 'phonecall':
                return 'Phonecall';
            case 'appointment':
                return 'Appointment';
            default:
                return typeCode;
        }
    };
    async formatName(name) {
        const newName = name.split(",");
        return `${newName[1]} ${newName[0]}`;
    };
    async getPhotoUrl(photo) {
        if (photo != undefined) {
            return `${globals.crmInstance}${photo}`;
        }
        return null;
    };

    openActivityInNewWindow(newEntityId, entityType, target) {
        var url = `${globals.crmInstance}/main.aspx?navbar=off&newWindow=true&pagetype=entityrecord&etn=${entityType}&id=${newEntityId}`;
        window.open(url, target, "popup");
        console.log("Activity ID: " + newEntityId + " URL: " + url);
    }

    getEntityId(url) {
        var regExp = /\(([^)]+)\)/;
        var matches = regExp.exec(url);
        return matches[1];
    }

    async task() {
        return new Promise(async (resolve, reject) => {
            var task = {
                "regardingobjectid_contact@odata.bind": "/contacts(" + sessionStorage.getItem('contactId') + ")",
                "actualdurationminutes": 30
            };

            try {
                let apiResponse = await httpService.postToCrm(`api/data/v9.1/tasks`, JSON.stringify(task));

                const newEntityId = this.getEntityId(apiResponse.headers.location);

                this.openActivityInNewWindow(newEntityId, "task", "_blank");
            } catch (error) {
                // alert(error);
                console.log(error);
                console.log("JSON part of failed API call: " + JSON.stringify(task))
            }
        })
    }

    async email() {
        return new Promise(async (resolve, reject) => {
            var email = {
                "regardingobjectid_contact@odata.bind": "/contacts(" + sessionStorage.getItem('contactId') + ")",
                "actualdurationminutes": 30,
                "email_activity_parties": [
                    {
                        "partyid_systemuser@odata.bind": "/systemusers(" + sessionStorage.getItem('userId') + ")",
                        "participationtypemask": 1
                    },
                    {
                        "partyid_contact@odata.bind": "/contacts(" + sessionStorage.getItem('contactId') + ")",
                        "participationtypemask": 2
                    }
                ],
                "@odata.type": "Microsoft.Dynamics.CRM.email"
            };

            try {
                let apiResponse = await httpService.postToCrm(`api/data/v9.1/emails`, JSON.stringify(email));

                const newEntityId = this.getEntityId(apiResponse.headers.location);

                this.openActivityInNewWindow(newEntityId, "email", "_blank");
            } catch (error) {
                // alert(error);
                console.log(error);
                console.log("JSON part of failed API call: " + JSON.stringify(email))
            }
        })
    }

    async phonecall(store) {
        return new Promise(async (resolve, reject) => {
            var phone = {
                "regardingobjectid_contact@odata.bind": "/contacts(" + sessionStorage.getItem('contactId') + ")",
                "phonecall_activity_parties": [
                    {
                        "partyid_systemuser@odata.bind": "/systemusers(" + sessionStorage.getItem('userId') + ")",
                        "participationtypemask": 2
                    },
                    {
                        "partyid_contact@odata.bind": "/contacts(" + sessionStorage.getItem('contactId') + ")",
                        "participationtypemask": 1
                    }
                ],
                "@odata.type": "Microsoft.Dynamics.CRM.phonecall",
                "phonenumber": store.state.CRMData.phone
            };

            try {
                let apiResponse = await httpService.postToCrm(`api/data/v9.1/phonecalls`, JSON.stringify(phone));

                const newEntityId = this.getEntityId(apiResponse.headers.location);

                this.openActivityInNewWindow(newEntityId, "phonecall", "_blank");
            } catch (error) {
                // alert(error);
                console.log(error);
                console.log("JSON part of failed API call: " + JSON.stringify(phone))
            }
        })
    }

    async appointment(type, campaignActivityId) {
        let start = moment();
        let end = moment().add(30, 'minutes');

        if (start.minute() <= 30) start.set({ minute: 30 });
        if (start.minute() > 30) start.set({ hour: start.hour() + 1, minute: 0 });
        if (end.minute() <= 30) end.set({ minute: 30 });
        if (end.minute() > 30) end.set({ hour: end.hour() + 1, minute: 0 });

        return new Promise(async (resolve, reject) => {
            var appointment = {
                "scheduledstart": start,
                "scheduledend": end,
                "subject": "",
                "appointment_activity_parties": [
                    {
                        "partyid_contact@odata.bind": "/contacts(" + sessionStorage.getItem('contactId') + ")",
                        "participationtypemask": 5
                    }
                ],
                "regardingobjectid_contact@odata.bind": "/contacts(" + sessionStorage.getItem('contactId') + ")"
            };

            console.log(appointment.scheduledstart)
            console.log(appointment.scheduledend)

            try {
                let apiResponse = await httpService.postToCrm(`api/data/v9.1/appointments`, JSON.stringify(appointment));

                const newEntityId = this.getEntityId(apiResponse.headers.location);

                this.openActivityInNewWindow(newEntityId, "appointment", "_blank");
            } catch (error) {
                // alert(error);
                console.log(error);
                console.log("JSON part of failed API call: " + JSON.stringify(appointment))
            }
        })
    }

    async chat() {
        return new Promise(async (resolve, reject) => {
            var chat = {
                "regardingobjectid_contact@odata.bind": "/contacts(" + sessionStorage.getItem('contactId') + ")"
            };

            try {
                let apiResponse = await httpService.postToCrm(`api/data/v9.1/lu_chats`, JSON.stringify(chat));

                const newEntityId = this.getEntityId(apiResponse.headers.location);

                this.openActivityInNewWindow(newEntityId, "lu_chat", "_blank");
            } catch (error) {
                // alert(error);
                console.log(error);
                console.log("JSON part of failed API call: " + JSON.stringify(chat))
            }
        })
    }

    async sms() {
        return new Promise(async (resolve, reject) => {
            var sms = {
                "regardingobjectid_contact@odata.bind": "/contacts(" + sessionStorage.getItem('contactId') + ")"
            };

            try {
                let apiResponse = await httpService.postToCrm(`api/data/v9.1/lu_smses`, JSON.stringify(sms));

                const newEntityId = this.getEntityId(apiResponse.headers.location);

                this.openActivityInNewWindow(newEntityId, "lu_sms", "_blank");
            } catch (error) {
                // alert(error);
                console.log(error);
                console.log("JSON part of failed API call: " + JSON.stringify(sms))
            }
        })
    }
}

export default new ActivityService();
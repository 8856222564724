import * as httpService from 'aether-service-library'
import { adminSetup } from 'aether-admin-vue2'
import { dashboardSetup } from 'aether-dashboards-vue2'
import { ferpaSprapinSetup } from 'aether-ferpa-sprapin-vue2'
import { generalSummarySetup } from 'aether-general-summary-vue2'
import { luoSummarySetup } from 'aether-luo-summary-vue2'
import { luoWorkbenchFormsSetup } from 'aether-luoworkbench-forms-vue2'
import { luoWorkbenchSetup } from 'aether-luoworkbench-vue2'
import { massComsSetup } from 'aether-mass-comms-vue2'
import { notificationsSetup } from 'aether-notifications-vue2'
import { opportunitiesSetup } from 'aether-opportunities-vue2'
import { recordSearchSetup } from 'aether-record-search-vue2'
import { registrarSummarySetup } from 'aether-registrar-summary-vue2'
import { reportsSetup } from 'aether-reports-vue2'
import { studentLookupSetup } from 'aether-student-lookup-vue2'
import { tabContainersSetup } from 'aether-tabcontainers-vue2'
import { uDevSummarySetup } from 'aether-university-development-summary-vue2'
import { uNavSetup } from 'aether-user-nav-actions-vue2'
import { userPrefSetup } from 'aether-user-preferences-vue2'

let services = [
    studentLookupSetup,
    generalSummarySetup,
    adminSetup,
    dashboardSetup,
    ferpaSprapinSetup,
    luoSummarySetup,
    luoWorkbenchFormsSetup,
    luoWorkbenchSetup,
    massComsSetup,
    notificationsSetup,
    recordSearchSetup,
    registrarSummarySetup,
    reportsSetup,
    tabContainersSetup,
    uNavSetup,
    userPrefSetup,
    opportunitiesSetup,
    uDevSummarySetup
]

class SetupService {
    setupAllServices() {
        try {
            for (const service of services) {
                service.setupServices(httpService)
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export default new SetupService()
import * as httpService from 'aether-service-library'

class PipelineService {
    async getPipeline(store, pipeline) {
        let pipelines = await this.getAllPipelines(store)

        let id = Number(pipeline);
        if (Number.isNaN(id)) {
            return pipelines.find(p => p.name === pipeline)
        } else {
            return pipelines.find(p => p.id === id)
        }
    }

    async getAllPipelines(store) {
        if (store.state.allPipelines & store.state.allPipelines.length > 0) {
            return store.state.allPipelines
        } else {
            let pipelineRes = await httpService.getFromAetherInfoApi('GET_OPPORTUNITY_PIPELINES')
            let pipelines = pipelineRes.data.map(p => {
                return {
                    name: p.PIPELINE_NAME,
                    id: p.PIPELINE_TABLE_ID
                }
            })
            store.commit('setPipelines', pipelines)
            return pipelines
        }
    }
}

export default new PipelineService();
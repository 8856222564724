var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMenu)?_c('section',[_c('v-menu',{attrs:{"offset-y":"","left":"","nudge-bottom":"14","nudge-right":"66","min-width":"230","content-class":"navigation-menu-content","disabled":_vm.avatars.length === 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"rounded-circle current-user-menu"},'div',attrs,false),onMenu),[_c('v-icon',{staticClass:"mt-1",attrs:{"small":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccount)+" ")]),_vm._v(" "+_vm._s(_vm.avatars.length)+" ")],1)]}}],null,false,3673757724)},[_c('v-list',{staticStyle:{"background-color":"var(--lu-primary)"}},_vm._l((_vm.avatars),function(avatar,index){return _c('div',{key:("avatar-id-" + (avatar.id)),staticClass:"avatars-group__item",staticStyle:{"background-color":"var(--lu-primary)"}},[_c('span',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'span',_vm.props,false),_vm.on),[_c('v-badge',{staticClass:"ms-4",attrs:{"bordered":"","bottom":"","overlap":"","dot":"","color":_vm.presenceColor(avatar.presence),"offset-x":"12","offset-y":"12"}},[_c('ProfileAvatar',{attrs:{"avatar":avatar}})],1)],1),_c('span',{staticClass:"ml-2",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(avatar.alt)+" ")]),(index < _vm.avatars.length - 1)?_c('v-divider',{staticClass:"mt-1 mb-1",staticStyle:{"opacity":"0.3"},attrs:{"color":"white"}}):_vm._e()],1)}),0)],1)],1):_c('section',{staticClass:"avatars-group pa-3 stacked"},[_vm._l((_vm.avatarsStackedLimited),function(avatar){return _c('div',{key:("avatar-id-" + (avatar.id)),staticClass:"avatars-group__item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('span',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'span',props,false),on),[_c('v-badge',{staticClass:"ms-4",attrs:{"bordered":"","bottom":"","overlap":"","dot":"","color":_vm.presenceColor(avatar.presence),"offset-x":"12","offset-y":"12"}},[_c('ProfileAvatar',{attrs:{"avatar":avatar}})],1)],1)]}}],null,true)},[_c('profile-tooltip',{attrs:{"avatar":avatar}})],1)],1)}),(_vm.avatarsSorted.length > _vm.stackedLimit)?_c('div',{staticClass:"avatars-group__item more"},[_c('v-menu',{attrs:{"left":"","max-height":_vm.menuMaxHeight,"nudge-bottom":"8","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"background-color":"var(--lu-primary)"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.icons.mdiDotsHorizontal))])],1)]}}],null,false,951877616),model:{value:(_vm.stackedMenu),callback:function ($$v) {_vm.stackedMenu=$$v},expression:"stackedMenu"}},[_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.avatarsSorted),function(avatar){return _c('v-list-item',{key:("avatar-menu-id-" + (avatar.id)),attrs:{"avatar":""}},[_c('v-badge',{staticClass:"ms-4",attrs:{"bordered":"","bottom":"","overlap":"","dot":"","color":_vm.presenceColor(avatar.presence),"offset-x":"12","offset-y":"12"}},[_c('ProfileAvatar',{attrs:{"avatar":avatar,"custom-class":"bordered small","size":"32px"}})],1),_vm._v("     "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(avatar.alt))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(avatar.role))])],1)],1)}),1)],1)],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
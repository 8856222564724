<script>
import { mapFields } from "vuex-map-fields";
import activityService from "../../../services/activity.service";

export default {
    props: {
        condense: Boolean
    },
    data() {
        return {
            activities: [
                {
                    label: "Task",
                    // url: this.createTask(),
                    target: "_blank",
                    icon: "solar:checklist-minimalistic-outline",
                },
                {
                    label: "Email",
                    // url: this.createEmail(),
                    target: "_blank",
                    icon: "solar:letter-linear",
                },

                {
                    label: "Phone Call",
                    // url: this.createPhonecall(),
                    target: "_blank",
                    icon: "solar:phone-outline",
                },
                {
                    label: "Chat",
                    // url: this.createChat(),
                    target: "_blank",
                    icon: "solar:chat-square-check-outline",
                },
                {
                    label: "Appointment",
                    // url: this.createAppointment(),
                    target: "_blank",
                    icon: "solar:calendar-add-outline",
                }
            ]
        }
    },
    methods: {
        async createActivity(activityType) {
            switch (activityType) {
                case "Task":
                    await activityService.task();
                    break;
                case "Email":
                    await activityService.email();
                    break;
                case "Phonecall":
                    await activityService.phonecall(this.$store);
                    break;
                case "Appointment":
                    await activityService.appointment();
                    break;
                case "Chat":
                    await activityService.chat();
                    break;
                case "SMS":
                    await activityService.sms();
                    break;
            }
        }
    },
    computed: {
        ...mapFields([
            "CRMData"
        ]),
    },
}
</script>

<template>
    <v-menu v-if="condense" left nudge-bottom="45" min-width="230" content-class="activity-menu-content">
        <template v-slot:activator="{ on: onMenu, attrs }">
            <span class="rcorners1" v-bind="attrs" v-on="onMenu">
                <v-icon>mdi-pencil-plus-outline</v-icon>
                <span class="ml-2">Activities</span>
            </span>
        </template>
        <v-list style="background-color: var(--lu-primary); opacity: 0.9; width: fit-content;">
            <v-list-item @click="createTask" style="margin-top: -1rem;">
                <v-icon class="mr-2" color="white">mdi-checkbox-marked-circle-plus-outline</v-icon>
                <div class="list-label">Create Task</div>
            </v-list-item>

            <v-divider color="white" style="opacity: 0.3;"></v-divider>

            <v-list-item @click="createPhonecall" style="margin-top: -1rem;">
                <v-icon class="mr-2" color="white">mdi-phone-plus-outline</v-icon>
                <div class="list-label">Create Phonecall</div>
            </v-list-item>

            <v-divider color="white" style="opacity: 0.3;"></v-divider>

            <v-list-item @click="createEmail" style="margin-top: -1rem;">
                <v-icon class="mr-2" color="white">mdi-email-plus-outline</v-icon>
                <div class="list-label">Create Email</div>
            </v-list-item>

            <v-divider color="white" style="opacity: 0.3;"></v-divider>

            <v-list-item @click="createChat" style="margin-top: -1rem;">
                <v-icon class="mr-2" color="white">mdi-chat-plus-outline</v-icon>
                <div class="list-label">Create Chat</div>
            </v-list-item>

            <v-divider color="white" style="opacity: 0.3;"></v-divider>

            <v-list-item @click="createSms" style="margin-top: -1rem;">
                <v-icon class="mr-2" color="white">mdi-message-plus-outline</v-icon>
                <div class="list-label">Create SMS</div>
            </v-list-item>

            <v-divider color="white" style="opacity: 0.3;"></v-divider>

            <v-list-item @click="createAppointment" style="margin-top: -1rem;">
                <v-icon class="mr-2" color="white">mdi-calendar-plus-outline</v-icon>
                <div class="list-label">Create Appointment</div>
            </v-list-item>
        </v-list>
    </v-menu>
    <div v-else>
        <v-tooltip bottom v-for="(link, index) in activities" :key="index" style="padding-right:20px">
            <template v-slot:activator="{ on, props }">
                <v-btn tile class="text-none" @click="createActivity(link.label)" v-bind="props" v-on="on"
                    style="margin-right:10px; font-size: 11pt;">
                    <iconify-icon :icon="link.icon" style="font-size: 20px; padding-right: 5px;"></iconify-icon>
                    {{ link.label }}
                </v-btn>
            </template>
            {{ link.label }}
        </v-tooltip>
    </div>
</template>

<style lang="scss" scoped>
.activity-menu-content {
    background-color: var(--lu-primary);
    width: fit-content !important;
    min-width: 0 !important;
}

.v-btn.theme--light {
    background-color: #f4f5fa !important;
}

.list-label {
    color: white;
}

.v-list-item {
    margin-top: 2px !important;
}

.rcorners1 {
    border-radius: 25px;
    // background: #dfdfdf;
    background-color: rgba(94, 86, 105, 0.08);
    padding: 6px;
}
</style>
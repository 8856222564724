<script>
export default ({
    props: {
        presence: {
            default: 'away',
            type: String
        }
    },
    computed: {
        presenceColor() {
            if (this.presence) {
                switch (this.presence.toLowerCase()) {
                    case 'away':
                        return 'orange'
                    case 'busy':
                        return 'red'
                    case 'holiday':
                        return 'purple'
                    case 'offline':
                        return 'grey'
                    case 'online':
                        return 'success'
                }
            } else {
                return 'grey'
            }
        }
    }
})
</script>

<template>
    <div class="presence" :class="presenceColor"></div>
    <!-- <v-badge bottom :class="presenceColor" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        
    </v-badge> -->
</template>
<script>
import contactService from "../../services/contact.service";

export default {
    data() {
        return {
            eloquaUrl: ''
        }
    },
    async mounted() {
        try {
            const siteId = '3016511';
            let email = await contactService.getEmail();
            const url = `https://login.eloqua.com/autoLogin?SiteId=${siteId}&Url=/apps/embed/salesTools/profiler?emailAddress=${email}`
            this.eloquaUrl = url; //'data:text/html;charset=utf-8,' + 
        } catch (err) {
            console.log(err)
        }
    }
}
</script>

<template>
    <div>
        <iframe height="700" width="100%" :src="eloquaUrl" scrolling="yes" frameBorder="0" />
    </div>
</template>
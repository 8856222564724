<template>
    <div style="color:var(--lu-alert)">
        {{ `ERROR 404: The page you were looking for (${route}) could not be found, or you have insufficient
        permissions.
        Please contact crmdev@liberty.edu for support.`}}
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: ""
        }
    },
    mounted() {
        let path = sessionStorage.getItem('pathNotFound')
        this.route = path ? path : window.location.pathname;
        sessionStorage.removeItem('pathNotFound')
    }
}
</script>

<style scoped></style>
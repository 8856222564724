import injector from 'vue-inject';
import globals from '../globals';
import moment from 'moment';
import httpService from './http.service';

export class FacultyAdvisorService {

    async fetchAdvisorInfo() {
        let advisorInfo = {
            name: '---',
            email: '---'
        };

        try {
            let apiResponse = await httpService.getFromAetherInfoApi('GET_FACULTY_ADVISOR', 'pidm', sessionStorage.getItem('pidm'))
            if (apiResponse.data.length > 0) {
                return this.formatAdvisor(apiResponse.data[0]);
            }

            return advisorInfo;
        }
        catch (error) {
            console.log(error)
            return advisorInfo;
        }
    }

    formatAdvisor(advisor) {
        return {
            advisorPidm: advisor.FACULTY_PIDM,
            name: advisor.FAC_NAME,
            email: advisor.FAC_EMAIL,
            term: advisor.EFF_TERM,
        }
    }
}

export default new FacultyAdvisorService();
<template>
  <div :style="themeStyles()">
    <component :is="resolveLayout" />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import NotFound from "@/layouts/NotFound.vue"
import globals from './globals'
import httpService from './services/http.service'
import userService from './services/user.service'
import customConfigService from './services/customConfig.service'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    NotFound
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (localStorage.getItem('userPidm') == null) return 'layout-blank'

      if (route.value.name === null) return null

      if (route.value.name === "Error" || route.value.name === "Login") return 'layout-blank'

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  async mounted() {
    try {

      var accountId = localStorage.getItem('accountIdentifier');
      var crmToken = localStorage.getItem('crm_token');
      var loggedIn = localStorage.getItem("loggedIn")

      if ((accountId === null || crmToken === null) && !loggedIn) {
        localStorage.setItem("loggedIn", true)
        localStorage.setItem("redirectUrl", window.location.href)
        window.location.href = window.location.origin + "/login";
      }
    }
    catch (error) {
      console.log(error);
    }
  },
  methods: {
    themeStyles() {
            let darkTheme = this.$vuetify.theme.dark
            return {
                '--basic-background-color': darkTheme ? '#1A1C24' : '#EDF0F2',
                '--basic-outline-color': darkTheme ? '#242730' : '#D4D6D7',
                '--basic-text-color': darkTheme ? '#FFFFFFF2' : '#1E1E1E',
                '--basic-subtext-color': darkTheme ? '#9597A1' : '#626363',
                '--darker-background-color': darkTheme ? '#121212' : 'white',
                '--lu-action': darkTheme ? '#65A8F9' : '#0968D9',
                '--basic-icon-color': darkTheme ? 'white' : '#19191A'
            }
        },
  }
}
</script>

<style>
.link:hover {
  cursor: pointer;
}
</style>
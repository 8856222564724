<template>
    <span>
        <div v-if="activeSummaryPage === 'LUO'">
            <Workbench @updateSelected="updateSelected" :tabsSelected="selected" />
        </div>
        <div v-else-if="activeSummaryPage === 'LUOA'">
            TO DO: Add LUOA Workbench
        </div>
        <div v-else>
            <NotFound />
        </div>
    </span>
</template>
  
<script>
import { Workbench } from 'aether-luoworkbench-vue2';
import NotFound from '../../NotFound.vue';

export default {
    components: {
        Workbench,
        NotFound
    },
    data() {
        return {
            visibilityOptions: localStorage.getItem("userVisibility").split(','),
            activeSummaryPage: localStorage.getItem("appName")
        }
    },
    methods: {
        updateSelected(newSelected) {
            this.$store.commit('setLuoWkbTabs', newSelected)
        }
    },
    computed: {
        selected() {
            return this.$store.state.luoWkbSelectedTabs;
        }
    },
    mounted() {

    }
}
</script>
  
<style scoped></style>
  
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import preset from '@/plugins/vuetify/default-preset/preset'


Vue.use(Vuetify)

const opts = {
    customVariables: ['~/./assets/variables.sass'],
    preset,
    theme: {
        icons: {
            iconfont: 'midi' || 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
        },
        theme: {
            options: {
                customProperties: true,
                variations: false,
            },     
        }
    }
}

export default new Vuetify(opts)
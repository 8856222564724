import injector from 'vue-inject';
import globals from '../globals';
import { globals as serviceGlobals } from 'aether-service-library';
import moment from 'moment';
import httpService from './http.service';

export class ContactService {
    async fetchContact(store) {
        return new Promise(async (resolve, reject) => {
            try {
                let apiRes = await httpService.getFromAetherInfoApi('GET_FULL_CONTACT', 'pidm,luid', `${sessionStorage.getItem('pidm')},${sessionStorage.getItem('libertyId')}`);
                let contactData = apiRes.data[0];

                const crmContact = {
                    fullphoto: await this.getPhoto(),
                    alt: contactData.FIRSTNAME + " " + contactData.LASTNAME,
                    src: await this.getPhoto(),
                    photo: await this.getPhoto(),
                    firstName: contactData.FIRSTNAME,
                    lastName: contactData.LASTNAME,
                    name: contactData.FIRSTNAME + " " + contactData.LASTNAME,
                    middleName: contactData.MIDDLENAME,
                    salutation: contactData.PREFIX,
                    suffix: contactData.SUFFIX,
                    birthdate: contactData.BIRTHDAY,
                    libertyId: sessionStorage.getItem('libertyId'),
                    pidm: sessionStorage.getItem('pidm'),
                    visa: null, //TODO
                    citizenship: null, //TODO
                    militaryInd: null, //TODO
                    preferredName: contactData.PREFNAME,
                    isCertificate: null, //TODO
                    isStaff: null, //TODO
                    isTrbc: null, //TODO
                    isDonor: null, //TODO
                    isStudent: null, //TODO
                    isAlumni: null, //TODO
                    gender: contactData.GENDER,
                    maritalStatus: contactData.MARITALSTATUS,
                    ethnicity: null, //TODO
                    citizenship: null, //TODO
                    priorSchool: null, //TODO
                    residentLivingAbroad: null, //TODO
                    dateOfDeath: null, //TODO
                    type: null, //TODO
                    religion: null, //TODO
                    vetranStatus: null, //TODO
                    interestLevel: null, //TODO
                    militaryBranch: null, //TODO
                    militaryStatus: null, //TODO
                    confidential: crmResults['new_confidential'],
                    legalAction: await this.getLegalAction(),
                    legalActionDate: await this.getLegalAction().fromDate,
                    email: this.getEmail(),
                    phone: getPhone()
                };

                store.commit('setCrmData', crmContact);
                return resolve(crmContact);
            }
            catch (error) {
                return reject(error);
            }
        })
    };

    async getLegalAction() {
        try {
            var apiResponse = await httpService.getFromAetherInfoApi('GET_LEGAL_ACTION_THREATENED', 'pidm', sessionStorage.getItem('pidm'));

            if (apiResponse.data.length > 0) {
                let legalActionData = apiResponse.data[0]
                return {
                    fromDate: legalActionData.FROMDATE,
                    toDate: legalActionData.TODATE,
                    activityDate: legalActionData.ACTIVITYDATE,
                    pidm: legalActionData.PIDM,
                    surrogateId: legalActionData.SURROGATEID,
                }
            } else {
                return null
            }
        } catch (err) {
            console.log(err)
        }
    }


    async getEmail() {
        let apiResponse = await httpService.getFromAetherInfoApi('GET_EMAIL_ADDRESSES', 'pidm,statusInd', `${sessionStorage.getItem('pidm')},A`)
        let sortedEmails = apiResponse.data.sort((a, b) => a.EMAL_RANK - b.EMAL_RANK)
        let per1Email = sortedEmails.find(e => e.GOREMAL_EMAL_CODE === 'PER1');

        let selectedEmail = per1Email ? per1Email : sortedEmails[0];

        return {
            id: selectedEmail.GOREMAL_SURROGATE_ID,
            type: selectedEmail.GOREMAL_EMAL_CODE,
            value: selectedEmail.GOREMAL_EMAIL_ADDRESS
        }
    }

    async getPhone() {
        let apiResponse = await httpService.getFromAetherInfoApi('GET_PHONES_BY_PIDM_AND_STATUS', 'pidm,statusInd', `${sessionStorage.getItem('pidm')},A`)
        let sortedPhones = apiResponse.data.sort((a, b) => a.TELE_RANK - b.TELE_RANK)
        let p1Phone = sortedPhones.find(p => p.SPRTELE_TELE_CODE === 'P1');
        let primaryPhone = sortedPhones.find(p => p.SPRTELE_PRIMARY_IND === 'Y')

        let selectedPhone = p1Phone ? p1Phone : (primaryPhone ? primaryPhone : sortedPhones[0])

        return {
            id: selectedPhone.SPRTELE_SURROGATE_ID,
            type: selectedPhone.SPRTELE_TELE_CODE,
            value: this.formatNumber(selectedPhone)
        }
    }

    formatNumber(number) {
        let phoneNumber = ''
        if (number.SPRTELE_INTL_ACCESS) {
            phoneNumber += `+${number.SPRTELE_INTL_ACCESS} `
        }
        if (number.SPRTELE_PHONE_AREA && number.SPRTELE_PHONE_AREA !== 'null') {
            phoneNumber += `(${number.SPRTELE_PHONE_AREA}) `
        }

        phoneNumber += (number.SPRTELE_PHONE_NUMBER.slice(0, 3) + '-' + number.SPRTELE_PHONE_NUMBER.slice(3))

        if (number.SPRTELE_PHONE_EXT) {
            phoneNumber += ` ${number.SPRTELE_PHONE_EXT}`
        }

        return phoneNumber
    }
    async getPacId() {
        return new Promise(async (resolve, reject) => {
            try {
                let apiResponse = await httpService.getFromAetherInfoApi('GET_PAC_ID', 'luid', sessionStorage.getItem('libertyId'))
                let data = apiResponse.data[0].PACIOLAN_CUSTOMER_ID;

                return resolve(data);
            } catch (error) {
                return reject(error);
            }
        });
    };

    async getPidm(luid) {
        try {
            let apiRes = await httpService.getFromAetherInfoApi('GET_CONTACT_FROM_LUID', 'luid', luid);

            return apiRes.data[0].SPRIDEN_PIDM
        } catch (err) {
            console.log(err)
        }
    }

    async getPacInfo() {
        return new Promise(async (resolve, reject) => {
            try {
                let apiResponse = await httpService.getFromAetherInfoApi('GET_PAC_INFO', 'luid', sessionStorage.getItem('libertyId'))
                let data = this.formatPacInfo(apiResponse.data[0]);

                return resolve(data);
            } catch (error) {
                return reject(error);
            }
        });
    };

    formatPacInfo(pacInfo) {
        return {
            pacAccountId: pacInfo.PACIOLAN_ACCOUNT_ID,
            name: pacInfo.FULL_NAME,
            account: pacInfo.ACCOUNT_NAME,
            donorType: pacInfo.DONOR_TYPE_STATUS,
            membership: pacInfo.FUNDRAISING_MEMBERSHIP,
            pledgeMembership: pacInfo.PLEDGE_MEMBERSHIP,
            nextDriveYearMembership: pacInfo.NEXT_DRIVE_YEAR_MEMBERSHIP,
            nextDriveYearPledge: pacInfo.NEXT_DRIVE_YEAR_PLEDGE,
            pacEmail: pacInfo.PREFERRED_EMAIL,
            pacPhone: pacInfo.PREFERRED_PHONE,
            pacAddress: pacInfo.BILLING_AND_MAILING_ADDRESS,
            accountRep: pacInfo.ACCOUNT_REPRESENTATIVE,
            donorRep: pacInfo.DONOR_REPRESENTATIVE,
            salesRep: pacInfo.SALES_REPRESENTATIVE,
        }
    }

    async getPhoto() {
        try {
            var apiResponse = await httpService.getImageUrl(`?libertyId=${sessionStorage.getItem('libertyId')}`);
            if (apiResponse.data != undefined) {
                return apiResponse.data.imageUrl;
            }
        }
        catch (error) {
            console.log(error);
        }
    };
}

export default new ContactService();
<template>
    <span>
        <div v-if="activeSummaryPage === 'LUO'">
            <PinnedWorkbench :tabsSelected="luoSelectedTabs" />
        </div>
        <div v-else-if="activeSummaryPage === 'LUOA'">
            TO DO: Add LUOA Workbench (pinned)
        </div>
        <div v-else>
            <NotFound />
        </div>
    </span>
</template>
  
<script>
import { PinnedWorkbench } from "aether-luoworkbench-vue2"
import NotFound from '../../NotFound.vue';

export default {
    components: {
        PinnedWorkbench,
        NotFound
    },
    computed: {
        luoSelectedTabs() {
            return this.$store.state.luoWkbSelectedTabs;
        }
    },
    data() {
        return {
            visibilityOptions: localStorage.getItem("userVisibility").split(','),
            activeSummaryPage: localStorage.getItem("appName")
        }
    },
    methods: {
        updateSelected(newSelected) {
            this.$store.commit('setLuoWkbTabs', newSelected)
        }
    },
    mounted() {

    }
}
</script>
  
<style scoped></style>
  
<script>
import {
  mdiAlert,
  mdiContentCopy
} from '@mdi/js';
import ProfileAvatar from '@/layouts/components/action-bar/profile/ProfileAvatar.vue';
import filters from '@/filters.js';
import { mapFields } from "vuex-map-fields";
import { Notification, NotificationIcon } from 'aether-notifications-vue2';
import globals from '../../../globals';
import { onBeforeMount } from '@vue/composition-api';
import contactService from '../../../services/contact.service';
import facultyAdvisorService from '../../../services/facultyAdvisor.service';

export default {
  components: { ProfileAvatar, Notification, NotificationIcon },
  filters: {
    notBlank: filters.notBlank,
    date: filters.date,
    yesNo: filters.yesNo
  },
  setup() {
    return {
      icons: {
        mdiAlert,
        mdiContentCopy
      },
    }
  },
  data: function () {

    return {
      loading: false,
      showFullHeader: true,
      getContact: {},
      advisorInfo: [],
      snackbar: false,
      timeout: 2000,
      notifications: [],
      legalActionObj: {},
      confidential: this.$store.state.Confidential
    }
  },
  methods: {
    copyLuid: function (luid) {
      navigator.clipboard.writeText(luid);
      this.snackbar = true;
    }
  },
  watch: {
    $route(to, from) {
      const arr = globals.showSearchArr;
      for (var i = 0; i < arr.length; i++) {
        if (to.path.includes(arr[i])) {
          this.showFullHeader = true;
          return;
        }
        else {
          this.showFullHeader = false;
        }
      }
    },
  },
  async mounted() {
    try {
      if(this.$route.name === 'Summary') {
        this.showFullHeader = true;
      }
      else {
        this.showFullHeader = false;
      };
      
      this.loading = true;

      if (sessionStorage.getItem('pidm')) {
        this.getContact = await contactService.fetchContact(this.$store);
        this.legalActionObj = {
          legalAction: this.getContact.legalAction,
          legalActionDate: this.getContact.legalActionDate
        }
      }

      if (sessionStorage.getItem('pidm')) {
        this.advisorInfo = await facultyAdvisorService.fetchAdvisorInfo();
      }

      switch (localStorage.getItem("appName")) {
        case 'LUO':
          this.notifications = ["atHold", "confidential", "legalAction", "phoneUnsub", "emailUnsub", "addressUnsub", "deceased"];
          break;
        case 'LUOA':
          this.notifications = ["affiliateMax", "affiliateAttribute", "confidential", "legalAction", "deceased"];
          break;
        case 'Flames Club':
        case 'Ticket Office':
        case 'Donor Engagement':
          this.notifications = ["legalAction", "phoneUnsub", "emailUnsub", "addressUnsub", "atHold", "confidential", "deceased"];
          break;
        default:
          this.notifications = ["confidential", "legalAction", "phoneUnsub", "emailUnsub", "addressUnsub", "deceased"];
          break;
      }

    } catch (error) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapFields([
      "CRMData",
      "LegalAction"
    ]),
    userSelected: function () {
      return globals.userName ? true : false
    }
  },
};
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined elevation="24">
        <v-card-title>
          <span v-show="!showFullHeader">
            <ProfileAvatar :avatar="getContact"></ProfileAvatar>
          </span>&nbsp;
          {{ getContact.name }} -&nbsp;
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span class="luid" v-bind="attrs" v-on="on" @click="copyLuid(getContact.libertyId)">
                {{ getContact.libertyId }} <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
              </span>
            </template>
            <span>Copy LUID</span>
          </v-tooltip>
          <v-snackbar color="primary" text top v-model="snackbar" :timeout="timeout">
            Copied {{ getContact.libertyId }} to clipboard
            <template v-slot:action="{ attrs }">
              <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
          <span v-show="!showFullHeader">
            <NotificationIcon v-for="notification in notifications" :key="notification.id" :type="notification"
              :legalAction="legalActionObj" :confidential="confidential" />
          </span>

          <v-spacer></v-spacer>
          <span style="float:right">
            <v-btn icon @click="showFullHeader = !showFullHeader">
              <v-icon>{{ showFullHeader ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </span>

        </v-card-title>
        <v-expand-transition>

          <div v-if="showFullHeader">
            <v-row>
              <v-col style="margin-left:10px; margin-right:10px; margin-top:-10px; margin-bottom:-20px">
                <Notification style="margin-bottom: 5px;" v-for="notification in notifications" :key="notification.id"
                  :type="notification" :legalAction="legalActionObj" :confidential="confidential" />
              </v-col>
            </v-row>
            <v-row>
              <v-col style="max-width:200px">
                <v-img v-if="getContact.fullphoto" width="150" class="contactPhoto" :aspect-ratio="1" :src="`${getContact.fullphoto}`" />
                <iconify-icon v-else icon="solar:user-bold" style="font-size: 200px;"></iconify-icon>
                <!-- <Image class="contactPhoto" :contact="getContact" /> -->
              </v-col>
              <v-col style="float:left">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="leftCol">Preferred Name</td>
                        <td class="rightCol">{{ getContact.preferredName | notBlank }}</td>
                      </tr>
                      <tr>
                        <td class="leftCol">Birthdate</td>
                        <td class="rightCol">{{ getContact.birthdate | date }}</td>
                      </tr>
                      <tr>
                        <td class="leftCol">Banner PIDM</td>
                        <td class="rightCol">{{ getContact.pidm | notBlank }}</td>
                      </tr>
                      <tr>
                        <td class="leftCol">Salutation</td>
                        <td class="rightCol">{{ getContact.salutation | notBlank }}</td>
                      </tr>
                      <tr>
                        <td class="leftCol">Middle Name</td>
                        <td class="rightCol">{{ getContact.middleName | notBlank }}</td>
                      </tr>
                      <tr>
                        <td class="leftCol">Suffix</td>
                        <td class="rightCol">{{ getContact.suffix | notBlank }}</td>
                      </tr>
                      <tr>
                        <td class="leftCol">Visa Status</td>
                        <td class="rightCol">{{ getContact.visa | notBlank }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="leftCol">Confidential</td>
                        <td class="rightCol">{{ getContact.confidential | yesNo }}</td>
                      </tr>
                      <tr>
                        <td class="leftCol">Legal Action Threatened</td>
                        <td class="rightCol">{{ getContact.legalAction | yesNo }}</td>
                      </tr>
                      <tr>
                        <td class="leftCol">Legal Action Threatened Date</td>
                        <td class="rightCol">{{ getContact.legalActionDate | date }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th colspan="2" class="header">Faculty Advisor Info</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="leftCol">Name</td>
                        <td class="rightCol">{{ advisorInfo.name | notBlank }}</td>
                      </tr>
                      <tr>
                        <td class="leftCol">E-mail</td>
                        <td class="rightCol">{{ advisorInfo.email | notBlank }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table> </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
tr td:first-child {
  width: 1%;
  white-space: nowrap;
  font-weight: 600;
}

.contactPhoto {
  margin-left: 20px;
  margin-bottom: 20px;
}

.contactSmallImg {
  height: 35px !important;
  width: 35px !important;
  min-width: 0px !important;
}

.luid {
  cursor: pointer;
}

.label {
  font-weight: 700;
}
</style>
import injector from 'vue-inject'
import globals from '../globals'
import moment from 'moment'
import httpService from './http.service'

export class UserService {
  async getUserRoles() {
    const distinctRoles = [];
    const finalRoles = [];
    let aetherTeamRoles = await httpService.getFromAetherInfoApi(
      'GET_ASSOCIATED_TEAMS_AND_ROLES',
      'emailAddress',
      localStorage.getItem('userName'),
    );

    for (const role of aetherTeamRoles.data) {
      if (!distinctRoles.find(r => r.role === role.ROLE_NAME)) {
        try {
          distinctRoles.push(role.ROLE_NAME)
        } catch (e) {
          console.log(e)
        }
      }
    };

    const linkRoles = [
      'Paciolan User',
      'Academic Planning Sheet User',
      'Self-Cert User',
      'Etrieve User',
      'Legal Action Threatened',
    ];

    for (const role of distinctRoles) {
        if (linkRoles.includes(role)) {
            finalRoles.push(role);
        }
    }
    return distinctRoles
  }

  //   async getUserId(accountId) {
  //     try {
  //       let crmData = await httpService.getFromCrm(
  //         `api/data/v9.1/systemusers?$filter=azureactivedirectoryobjectid eq '${accountId}'`,
  //       )
  //       let crmResults = crmData.data.value[0]
  //       window.localStorage.setItem('userId', crmResults.systemuserid)
  //       window.localStorage.setItem('userName', crmResults.domainname)

  //       return crmResults
  //     } catch (error) {
  //       // alert(error)
  //       console.log('Failed getting User from Account')
  //       console.log(error)
  //     }
  //   }

  // async fetchBusinessUnit(store) {
  //   try {
  //     let crmData = await httpService.getFromCrm(
  //       `api/data/v9.1/systemusers(${localStorage.getItem('userId')})?$select=_businessunitid_value`,
  //     )
  //     let crmResults = crmData.data

  //     store.commit('setUserBusinessUnit', crmResults['_businessunitid_value@OData.Community.Display.V1.FormattedValue'])
  //     window.localStorage.setItem('businessUnitId', crmResults._businessunitid_value)
  //     window.localStorage.setItem(
  //       'businessUnitName',
  //       crmResults['_businessunitid_value@OData.Community.Display.V1.FormattedValue'],
  //     )

  //     return crmResults['_businessunitid_value@OData.Community.Display.V1.FormattedValue']
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  //   async fetchUser(store) {
  //     try {
  //       let userId = localStorage.getItem('userId')
  //       let crmData = await httpService.getFromCrm(`api/data/v9.1/systemusers(${userId})`)
  //       let crmResults = crmData.data
  //       let systemuser = {}

  //       store.commit('setUserBusinessUnit', crmResults['_businessunitid_value@OData.Community.Display.V1.FormattedValue'])
  //       window.localStorage.setItem('businessUnitId', crmResults._businessunitid_value)
  //       window.localStorage.setItem(
  //         'businessUnitName',
  //         crmResults['_businessunitid_value@OData.Community.Display.V1.FormattedValue'],
  //       )
  //       systemuser = {
  //         id: crmResults['systemuserid'],
  //         name: crmResults['firstname'] + ' ' + crmResults['lastname'],
  //         photo: crmResults.entityimage_url,
  //         alt: crmResults['firstname'] + ' ' + crmResults['lastname'],
  //         src: await this.getPhotoUrl(crmResults.entityimage_url),
  //         title: crmResults['title'],
  //         businessUnit: crmResults['_businessunitid_value@OData.Community.Display.V1.FormattedValue'],
  //         userType: await this.getUserRole(userId),
  //       }
  //       return systemuser
  //     } catch (error) {
  //       // alert(error)
  //       console.log(error)
  //     }
  //   }

  //   async getPhotoUrl(photo) {
  //     try {
  //       if (photo == null) {
  //         // const graphUrl = await httpService.getCustomUrl('https://graph.microsoft.com/v1.0/me/photo/\$value');
  //         // return graphUrl;
  //       } else {
  //         return `${globals.crmInstance}${photo}`
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }

  //     // if (photo != undefined) {
  //     //     return `${globals.crmInstance}${photo}`;
  //     // }
  //     // return null;
  //   }

  // async fetchNotifications() {
  //   try {
  //     let crmData = await httpService.getFromCrm(
  //       `api/data/v9.1/lu_notifications?$filter=_lu_regarding_value eq '${localStorage.getItem(
  //         'userId',
  //       )}' and statecode eq 0`,
  //     )
  //     let crmResults = crmData.data.value
  //     let notifications = []

  //     for (var i = 0; i < crmResults.length; i++) {
  //       notifications.push({
  //         id: crmResults[i]['lu_notificationid'],
  //         text: crmResults[i]['lu_name'],
  //         user: crmResults[i]['_lu_regarding_value'],
  //         statecode: crmResults[i]['statecode'],
  //         createdon: moment(crmResults[i].createdon).format('lll'),
  //         createdby: await this.formatName(crmResults[i]['_createdby_value@OData.Community.Display.V1.FormattedValue']),
  //       })
  //     }

  //     // return notifications;

  //     //for testing notifications ui
  //     return [
  //       {
  //         text: 'Hello',
  //         createdBy: 'me',
  //         createdOn: 'today',
  //       },
  //       {
  //         text: 'Goodbye',
  //         createdBy: 'you',
  //         createdOn: 'tomorrow',
  //       },
  //     ]
  //   } catch (error) {
  //     // alert(error)
  //     console.log(error)
  //   }
  // }

  async clearNotification(id) {
    const body = {
      statecode: 1,
    }
    let apiResponse = await httpService.patchCrm(`api/data/v9.1/lu_notifications(${id})`, JSON.stringify(body))

    return apiResponse
  }

  async formatName(name) {
    const newName = name.split(',')
    return `${newName[1]} ${newName[0]}`
  }
}

export default new UserService()
